import { Button, Modal } from "antd";
import { Column, Typography } from "core/util-styled-components";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ButtonOptionsTable = ({ data, onAddTermEmb, onViewMore }) => {
  const [isEndRegisted, setIsEndRegisted] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    setIsEndRegisted(Array.isArray(data) && data.length && data[0].TipoTerminoEmbarazo);
    return () => {};
  }, [data]);

  const goToList = () => {
    nav("/listado-termino-embarazo");
  };

  const confirmAdd = () => {
    Modal.confirm({
      content: "¿Estas seguro de querer agregar otro termino de embarazo?",
      onOk: onAddTermEmb,
    });
  };

  return (
    <Column gap="2.5px">
      {isEndRegisted && <span style={{ color: "green", fontSize: 10, fontWeight: "bold" }}>Termino de embarazo registrado</span>}
      {isEndRegisted ? (
        <Fragment>
          <Button type="dashed" size="small" onClick={goToList}>
            <Typography variant="label">Ir al listado</Typography>
          </Button>
          {/* <Button type="dashed" size="small" onClick={confirmAdd}>
            <Typography variant="label">Agregar otro termino embarazo</Typography>
          </Button> */}
        </Fragment>
      ) : (
        <Button size="small" type="dashed" onClick={onAddTermEmb}>
          <Typography variant="label">
            <i className="ri-add-line" /> Termino de embarazo
          </Typography>
        </Button>
      )}
      <Button size="small" type="primary" onClick={onViewMore}>
        <Typography color="inherit" variant="label">
          Ver mas
        </Typography>
      </Button>
    </Column>
  );
};

export default ButtonOptionsTable;
