import { Input } from "antd";
import { HOST_API } from "core/hooks/host_api";
import useAxios from "core/hooks/useAxios";
import { DropdownStyled } from "core/ts_formulario_model/src/App/Components/Molecules/FormControl/styled";
import { Column, Row, Typography } from "core/util-styled-components";
import React, { useEffect, useState } from "react";

const TerminoEmbarazoFilter = ({ hook, filterData, setFilterData }) => {
  const Tipos: any = useAxios({
    method: "GET",
    executeAtStart: true,
    host_api: HOST_API,
    url: "/api/v1/embarased-end/types/list",
  });
  const [filterProps, setFilterProps] = useState({ docNumber: "", type: [], year: "2024" });
  const [errorYear, setErrorYear] = useState(false);

  useEffect(() => {
    if (!errorYear) {
      hook.startRequest({ anio: filterProps.year });
    }
    return () => {};
    //eslint-disable-next-line
  }, [filterProps.year]);

  useEffect(() => {
    if (Array.isArray(hook.data)) {
      onFilter();
    }

    return () => {};
    // eslint-disable-next-line
  }, [hook.data, filterProps.type, filterProps.docNumber]);

  const onFilter = async () => {
    let data = hook.data;
    if (filterProps.docNumber) {
      data = data.filter((el) => el.Num_Doc.includes(filterProps.docNumber));
    }

    if (filterProps.type.length) {
      data = data.filter((el) => el.idTipoTerminoEmbarazo === filterProps.type[0].idTipoTerminoEmbarazo);
    }

    setFilterData(data);
  };

  const onChange = (type, value) => {
    setErrorYear(type === "year" && !(value >= 1990 && value <= 2099));
    setFilterProps({ ...filterProps, [type]: value });
  };

  return (
    <Row gap="10px" style={{ marginBottom: 20 }}>
      <Column alignItems="flex-start">
        <Typography variant="label">N° Documento:</Typography>
        <Input type="number" value={filterProps.docNumber} onChange={({ target }) => onChange("docNumber", target.value)} />
      </Column>
      <Column alignItems="flex-start">
        <Typography variant="label">Tipo de termino de embarazo:</Typography>
        <DropdownStyled
          clearable
          values={filterProps.type}
          style={{ minWidth: 250 }}
          onChange={(value) => onChange("type", value)}
          options={Tipos.data?.recordset}
          labelField="descripcion"
          valueField="idTipoTerminoEmbarazo"
        />
      </Column>
      <Column alignItems="flex-start">
        <Typography variant="label">
          Año:{" "}
          {errorYear && (
            <Typography color="red" variant="label">
              (Incorrecto)
            </Typography>
          )}
        </Typography>
        <Input
          type="number"
          min="1900"
          max="2099"
          step="1"
          value={filterProps.year}
          onChange={({ target }) => onChange("year", target.value)}
          style={
            errorYear
              ? {
                  backgroundColor: "#ff00ff31",
                  border: "solid 1px red",
                }
              : {}
          }
        />
      </Column>
    </Row>
  );
};

export default TerminoEmbarazoFilter;
