import { VigilanceConfig } from "api/models/Vigilance/config";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Tooltip } from "antd";
import useAxios from "core/hooks/useAxios";
import { Column, Row, Typography } from "core/util-styled-components";
import { RiskFactorAPI } from "api/models/RiskFactor";
import PacientFilterV2 from "./components/PacientFilter_v2";
import VigilanceHistory from "./components/VigilanceHistory";
import { useNavigate } from "react-router-dom";
import { calcularFechaProbableYEdadGestacional } from "core/utils/util.core";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import ExtraCard from "./components/ExtraCard";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import TerminoEmbarazo from "./components/TerminoEmbarazo";
import ButtonOptionsTable from "./components/ButtonOptionsTable";

const FactorRiesgo = ({ ubicacion, color, factorRiesgo, onClick = () => {}, fo, uo }) => {
  return (
    <Row justifyContent="space-between" gap="10px">
      <Typography variant="label" onClick={onClick}>
        <Row gap="10px">
          {fo && (
            <Typography style={{ fontWeight: "600" }}>
              <Row gap="5px">
                {color && <div>{getColor(color)}</div>}
                {factorRiesgo}
              </Row>
            </Typography>
          )}
          {uo && ubicacion}
        </Row>
      </Typography>
    </Row>
  );
};

const getColor = (color) => {
  return color === "AMARILLO" ? "🟡" : color === "ROJO" ? "🔴" : color === "VERDE" ? "🟢" : "⚪";
};

const Vigilance = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [pacientSeleccionado, setPacientSeleccionado] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTerminoEmbarazo, setModalTerminoEmbarazo] = useState(false);
  const ListPregnatVigilances = useAxios(VigilanceConfig.apiConfig.vigilanceListByPregnatData);
  const ListPuerperios = useAxios(VigilanceConfig.apiConfig.puerperios);
  // const ListVigilances = useAxios(VigilanceConfig.apiConfig.list);
  const ListRiskFactor = useAxios(RiskFactorAPI.list);
  const [editData, setEditData] = useState({});
  const [modalType, setModalType] = useState("add");
  const [activeModal, setActiveModal] = useState("");
  const [search, setSearch] = useState("");
  const [colorSelected, setColorSelected] = useState([]);
  const [condicionSelected, setCondicionSelected] = useState([]);
  const [riskFactor, setRiskFactor] = useState([]);
  const [colorCounter, setColorCounter] = useState({ ROJO: 0, AMARILLO: 0, VERDE: 0 });
  const nav = useNavigate();

  const selectPacient = (data) => {
    setPacientSeleccionado({
      pais: data.pais,
      departamento: data.departamento,
      provincia: data.provincia,
      distrito: data.distrito,
      dni: data.nroDocumento,
      fechaNacimiento: data.fechaNacimiento,
      direccion: data.direccionDomicilio,
      edad: data.edad,
      nombreCompleto: `${data.nombres} ${data.apellidoPaterno} ${data.apellidoMaterno}`,
      genero: data.idTipoSexo === 1 ? "MASCULINO" : "FEMENINO",
    });
  };

  const filterData = (data) => {
    // En caso no haya ningun filtro
    if (colorSelected.length === 0 && riskFactor.length === 0 && search.length === 0 && condicionSelected.length === 0) return data;

    let color = colorSelected.length > 0 ? colorSelected[0]?.color : "";
    let factorRiesgo = riskFactor.length > 0 ? riskFactor[0].idFactorRiesgo : "";
    let condicion = condicionSelected.length ? condicionSelected[0].name : "";

    let type = "";

    if (color && factorRiesgo) {
      type = "color_factorRiesgo";
    } else if (color) {
      type = "color";
    } else if (factorRiesgo) {
      type = "factorRiesgo";
    }

    if (condicion.length > 0) {
      type = type + "condicion";
    }

    if (search.length > 0) {
      type = type + "&search";
    }

    const dataResponse = data.filter((item) => {
      const { factorRiesgo, Num_doc } = item;

      let coincidenSearch = true;

      if (type.includes("&search")) {
        if (type === "&search") {
          // Búsqueda sin otros filtros
          return Num_doc?.includes(search);
        } else {
          coincidenSearch = Num_doc?.includes(search);
        }
      }

      if (type.includes("color_factorRiesgo")) {
        const r = factorRiesgo.filter((item) => {
          const { idFactorRiesgo, color } = item;
          return riskFactor.find((item) => parseInt(item.idFactorRiesgo) === parseInt(idFactorRiesgo)) && colorSelected.filter((item) => item.color === color).length > 0;
        });
        return r.length > 0 && coincidenSearch;
      }
      if (type.includes("color")) {
        const r = factorRiesgo.filter((item) => {
          const { color } = item;
          return colorSelected.filter((item) => item.color === color).length > 0;
        });
        return r.length > 0 && coincidenSearch;
      }

      if (type.includes("factorRiesgo")) {
        const r = factorRiesgo.filter((item) => {
          const { idFactorRiesgo } = item;
          return riskFactor.find((item) => parseInt(item.idFactorRiesgo) === parseInt(idFactorRiesgo));
        });
        return r.length > 0 && coincidenSearch;
      }

      if (type.includes("condicion")) {
        const r = factorRiesgo.filter((item) => {
          const { CONDICION } = item;
          return condicionSelected.find((item) => item.name === CONDICION);
        });
        return r.length > 0 && coincidenSearch;
      }
    });

    return dataResponse;
  };

  const getCounter = (data) => {
    let counter = { ROJO: 0, AMARILLO: 0, VERDE: 0 };
    data.forEach((item) => {
      item.factorRiesgo.forEach((item) => {
        counter[item.color] = counter[item.color] + 1;
      });
    });
    return counter;
  };

  const onSearchPuerperio = (dni) => {
    ListPuerperios.startRequest({
      dni,
    });
    setModalVisible(true);
  };

  useEffect(() => {
    if (Array.isArray(ListPregnatVigilances.data)) {
      setColorCounter(getCounter(filterData(ListPregnatVigilances.data)));
    }

    return () => {};
    // eslint-disable-next-line
  }, [ListPregnatVigilances.data, colorSelected, riskFactor, search, condicionSelected]);

  return (
    <div>
      <Card
        title="Vigilancia"
        style={{ marginBottom: "1em" }}
        bodyStyle={{
          maxWidth: "100%",
          overflow: "auto",
        }}
        extra={
          !isTabletOrMobile && (
            <ExtraCard
              condicion={condicionSelected}
              setCondicion={setCondicionSelected}
              ListRiskFactor={ListRiskFactor.data}
              colorCounter={colorCounter}
              search={search}
              colorSelected={colorSelected}
              riskFactor={riskFactor}
              setColorSelected={setColorSelected}
              setRiskFactor={setRiskFactor}
              setSearch={setSearch}
            />
          )
        }
      >
        {isTabletOrMobile && (
          <ExtraCard
            condicion={condicionSelected}
            setCondicion={setCondicionSelected}
            ListRiskFactor={ListRiskFactor.data}
            colorCounter={colorCounter}
            search={search}
            colorSelected={colorSelected}
            riskFactor={riskFactor}
            setColorSelected={setColorSelected}
            setRiskFactor={setRiskFactor}
            setSearch={setSearch}
          />
        )}
        <div style={{ maxWidth: "100%", overflow: "auto" }}>
          <Table
            loading={ListPregnatVigilances.loading}
            dataSource={filterData(ListPregnatVigilances.data)}
            columns={[
              {
                title: "DNI",
                dataIndex: "Num_doc",
                width: "80px",
                render: (dni) => (
                  <Tooltip title="Ir a información gestante">
                    <Typography
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => {
                        nav("/gestantes", {
                          state: {
                            dni,
                          },
                        });
                      }}
                    >
                      {dni}
                    </Typography>
                  </Tooltip>
                ),
              },
              {
                title: "Factor riesgo",
                dataIndex: "factorRiesgo",
                render: (data) => (
                  <Column alignItems="flex-start">
                    {data.map((item, index) => {
                      const { factorRiesgo, color, UBICACION } = item;
                      return <FactorRiesgo key={index} ubicacion={UBICACION?.split("|")[UBICACION.split("|").length - 1]} color={color} factorRiesgo={factorRiesgo} fo={true} />;
                    })}
                  </Column>
                ),
              },
              {
                title: "Ubicación",
                dataIndex: "factorRiesgo",
                render: (data) => (
                  <Column alignItems="flex-start">
                    {data.map((item, index) => {
                      const { factorRiesgo, color, UBICACION } = item;
                      return <FactorRiesgo key={index} ubicacion={UBICACION?.split("|")[UBICACION.split("|").length - 1]} color={color} factorRiesgo={factorRiesgo} uo={true} />;
                    })}
                  </Column>
                ),
              },
              {
                title: "Edad gestacional",
                dataIndex: "factorRiesgo",
                render: (f) => {
                  if (f.length <= 0) {
                    return <Typography>---</Typography>;
                  }
                  const fur = f[0].FUR;

                  if (!fur) {
                    return <Typography>---</Typography>;
                  }

                  const edadGes = calcularFechaProbableYEdadGestacional(fur).edadGestacional;

                  return (
                    <Typography>
                      {edadGes.semanas} semanas {edadGes.dias} día
                    </Typography>
                  );
                },
              },
              {
                title: "Fecha probable de parto",
                dataIndex: "factorRiesgo",
                render: (f) => {
                  if (f.length <= 0) {
                    return <Typography>---</Typography>;
                  }
                  const fur = f[0].FUR;

                  if (!fur) {
                    return <Typography>---</Typography>;
                  }

                  const fecProbable = calcularFechaProbableYEdadGestacional(fur).fechaProbableParto;

                  return <Typography>{fecProbable}</Typography>;
                },
              },
              {
                title: "Fichas",
                dataIndex: "factorRiesgo",
                render: (f) => {
                  if (f.length <= 0) {
                    return <Typography>---</Typography>;
                  }

                  const Condicion = f[0].FICHA;
                  if (!Condicion) {
                    return <Typography>---</Typography>;
                  }

                  return <Typography>{Condicion}</Typography>;
                },
              },
              {
                title: "CONDICION",
                dataIndex: "factorRiesgo",
                render: (f) => {
                  if (f.length <= 0) {
                    return <Typography>---</Typography>;
                  }
                  const Condicion = f[0].CONDICION;
                  if (!Condicion) {
                    return <Typography>---</Typography>;
                  }

                  return <Typography>{Condicion}</Typography>;
                },
              },
              {
                title: "Puerperios",
                render: ({ Num_doc, factorRiesgo }) => {
                  const Condicion = factorRiesgo.length > 0 ? factorRiesgo[0].CONDICION : null;
                  if (Condicion === "PUERPERA") {
                    return (
                      <Button
                        size="small"
                        type="link"
                        onClick={() => {
                          onSearchPuerperio(Num_doc);
                        }}
                      >
                        Puerperios
                      </Button>
                    );
                  }
                  return <Typography>---</Typography>;
                },
              },
              {
                title: "",
                dataIndex: "factorRiesgo",
                render: (data) => (
                  <ButtonOptionsTable
                    data={data}
                    onAddTermEmb={() => {
                      setModalTerminoEmbarazo(true);
                      setEditData(data[0]);
                      selectPacient(data[0]);
                    }}
                    onViewMore={() => {
                      setModalType("edit");
                      setActiveModal(true);
                      setEditData(data[0]);
                      selectPacient(data[0]);
                    }}
                  />
                ),
              },
            ]}
          ></Table>
        </div>
      </Card>
      <Modal
        title="Puerperio"
        open={modalVisible}
        footer={null}
        width={"95%"}
        centered
        destroyOnClose
        closable={false}
        onCancel={() => {
          setModalVisible(false);
        }}
        bodyStyle={{
          overflow: "auto",
        }}
      >
        <Table
          style={{ width: "100%" }}
          dataSource={ListPuerperios.data}
          columns={generateMapTable([
            { title: "Tip. Doc.", dataIndex: "Tipo_Doc_Madre" },
            { title: "Documento", dataIndex: "NU_DOC_MADRE" },
            { title: "Año", dataIndex: "Anio" },
            { title: "Condicion de parto", dataIndex: "Condicion_Parto" },
            { title: "Fecha de atencion", dataIndex: "Fecha_Atencion", render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy") },
            { title: "Fecha de parto", dataIndex: "Fecha_Parto", render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy") },
            { title: "Renaes", dataIndex: "Renaes" },
            { title: "EESS", dataIndex: "Nombre_Establecimiento" },
            { title: "Dias de parto", dataIndex: "Tipo_Parto" },
            { title: "Variables", dataIndex: "Variables" },
            { title: "Dias de puerperio", dataIndex: "dias_puerperio" },
            { title: "Edad", dataIndex: "edad_reg" },
            { title: "Fecha de nacimiento", dataIndex: "fecha_nac", render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy") },
          ])}
          pagination={false}
        />
      </Modal>

      <Modal
        title={`Vigilancia de gestante`}
        open={activeModal}
        footer={null}
        width="80%"
        bodyStyle={{
          padding: "0",
          width: "100%",
        }}
        centered
        destroyOnClose
        closable={false}
        onCancel={() => {
          setModalType("add");
          setActiveModal(false);
          setPacientSeleccionado({});
          setEditData({});
        }}
      >
        <Column gap="10px">
          <PacientFilterV2 dni_preload={editData?.Num_doc} tipoDoc={editData?.AbrevTipoDoc} data={pacientSeleccionado} onChange={(data) => setPacientSeleccionado(data)} />
          <VigilanceHistory nroDocumento={editData?.Num_doc} />
        </Column>
      </Modal>

      <Modal
        title="Termino de embarazo"
        footer={null}
        centered
        open={modalTerminoEmbarazo}
        onCancel={() => {
          setModalTerminoEmbarazo(false);
          setPacientSeleccionado({});
          setEditData({});
        }}
        closable={false}
        destroyOnClose
        width="80%"
        bodyStyle={{ padding: 0, width: "100%" }}
      >
        <TerminoEmbarazo
          paciente={editData}
          onClose={() => {
            setModalTerminoEmbarazo(false);
            setPacientSeleccionado({});
            setEditData({});
          }}
        />
      </Modal>
    </div>
  );
};

export default Vigilance;
