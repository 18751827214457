import { Input, Tag } from "antd";
import { DropdownStyled } from "core/ts_formulario_model/src/App/Components/Molecules/FormControl/styled";
import { Row, Typography } from "core/util-styled-components";
import React from "react";

const ExtraCard = ({ setCondicion, condicion, colorCounter, colorSelected, setColorSelected, ListRiskFactor, riskFactor, setRiskFactor, search, setSearch }) => {
  return (
    <Row gap="5px" responsiveReorder style={{ maxWidth: "100%" }}>
      <Row>
        <Typography>Riesgo: </Typography>{" "}
        <Tag color="red-inverse">
          <Row gap="10px">
            <Typography variant="label" color="inherit">
              Alto
            </Typography>
            <Typography variant="h4" color="inherit">
              {colorCounter.ROJO}
            </Typography>
          </Row>
        </Tag>
        <Tag color="gold-inverse">
          <Row gap="10px">
            <Typography variant="label" color="inherit">
              Moderado
            </Typography>
            <Typography variant="h4" color="inherit">
              {colorCounter.AMARILLO}
            </Typography>
          </Row>
        </Tag>
        <Tag color="green-inverse">
          <Row gap="10px">
            <Typography variant="label" color="inherit">
              Bajo
            </Typography>
            <Typography variant="h4" color="inherit">
              {colorCounter.VERDE}
            </Typography>
          </Row>
        </Tag>
      </Row>
      <DropdownStyled
        options={[
          {
            color: "ROJO",
            name: "Alto",
          },
          {
            color: "AMARILLO",
            name: "Moderado",
          },
          {
            color: "VERDE",
            name: "Bajo",
          },
        ]}
        labelField={"name"}
        valueField={"color"}
        clearable
        values={colorSelected}
        onChange={(values) => {
          setColorSelected(values);
          setRiskFactor([]);
        }}
        placeholder="Color"
        style={{ minWidth: 200 }}
      />
      <DropdownStyled
        options={[
          {
            name: "PUERPERA",
          },
          {
            name: "GESTANTE",
          },
        ]}
        labelField={"name"}
        valueField={"name"}
        clearable
        values={condicion}
        onChange={(values) => {
          setCondicion(values);
        }}
        placeholder="Condicion"
        style={{ minWidth: 200 }}
      />

      <DropdownStyled
        options={ListRiskFactor.filter((item) => (colorSelected.length ? item.color === colorSelected[0]?.color : true))}
        labelField={"descripcion"}
        valueField={"idFactorRiesgo"}
        style={{ minWidth: 400 }}
        clearable
        values={riskFactor}
        searchBy="descripcion"
        onChange={(values) => {
          setRiskFactor(values);
        }}
      />
      <Input.Search placeholder="Buscar por DNI..." value={search} onChange={({ target }) => setSearch(target.value)} allowClear />
    </Row>
  );
};

export default ExtraCard;
