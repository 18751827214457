import React, { useState } from "react";
import { Button, Card, Table } from "antd";
import useAxios from "core/hooks/useAxios";
import { addHours, format } from "date-fns";
import { VigilanceAPI } from "api/models/Vigilance";
import { generateMapTable } from "core/utils/tables";
import { PacientDocs } from "api/models/Pacient/data";
import { Row, Typography } from "core/util-styled-components";
import TerminoEmbarazoFilter from "./components/TerminoEmbarazoFilter";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import Excel from "core/utils/excel";

const TermEmb = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const TermEmbList = useAxios(VigilanceAPI.terminosEmb);
  const [filterData, setFilterData] = useState([]);

  const getRenderTipDoc = (idTipoDoc) => {
    const doc = PacientDocs.find((el) => el.Id_Tipo_Documento === idTipoDoc);
    return (
      <Row>
        <Typography>{doc.Abrev_Tipo_Doc}</Typography>
        {/* <Typography variant="label">{doc.Descripcion_Tipo_Documento}</Typography> */}
      </Row>
    );
  };

  const onExcelExport = () => {
    Excel(
      filterData.map((el) => {
        return { ...el, FechaTerminoEmbarazo: format(addHours(new Date(el.FechaTerminoEmbarazo), 5), "dd/MM/yyyy") };
      }),
      "Terminos de embarazo"
    );
  };

  return (
    <Card
      title="Terminos de embarazo"
      extra={
        <Button type="primary" onClick={onExcelExport} loading={TermEmbList.loading} icon={<i className="ri-file-excel-line" />}>
          {isTabletOrMobile ? "" : "Descargar data"}
        </Button>
      }
    >
      <TerminoEmbarazoFilter hook={TermEmbList} filterData={filterData} setFilterData={setFilterData} />
      <Table
        dataSource={filterData}
        columns={generateMapTable([
          {
            dataIndex: "idTipoDocumento",
            title: "Tipo de documento",
            render: getRenderTipDoc,
          },
          {
            dataIndex: "Num_Doc",
            title: "Numero de documento",
          },
          {
            dataIndex: "FechaTerminoEmbarazo",
            title: "Fecha de termino de embarazo",
            render: (f) => format(addHours(new Date(f), 5), "dd / MM / yyyy"),
          },
          //   "idTipoTerminoEmbarazo",

          {
            title: "Tipo de termino de embarazo",
            dataIndex: "Descripcion",
          },
          "Establecimiento",
          "Observacion",
        ])}
      />
    </Card>
  );
};

export default TermEmb;
